<template>
  <div>
    <Toast position="top-right" />
    <b-overlay
      id="overlay-background"
      :show="
        !clienteTemModulo &&
        mostrarOverlay &&
        this.$route.query.overlay != 'false'
      "
      :variant="'transparent'"
      :opacity="1"
      :blur="'2px'"
      rounded="sm"
    >
      <template #overlay v-if="!clienteTemModulo">
        <OverlayModuleSignUp @hide="esconderOverlay" />
      </template>
      <b-row v-if="clienteTemModulo">
        <b-col md="12">
          <HeaderFilters
            :tipo="mapaTipo"
            @mudarTipo="onChangeMapaTipo"
            @carregarHeatmap="carregarHeatmap"
            @carregarMapaAgrupado="carregarMapaAgrupado"
            @criarMarkersIndividuais="criarMarkersIndividuais"
          >
            <div class="d-flex">
              <b-form-select
                v-model="respondidoNaoRespondidoSelecionado"
                v-width="150"
                :options="optionsRespondidoNaoRespondido"
                class="mr-3"
              ></b-form-select>

              <pv-multiselect
                :filter="true"
                v-model="unidadeSelecionada"
                v-width="150"
                :options="optionsUnidades"
                optionLabel="text"
                class="mr-3"
              >
              </pv-multiselect>

              <pv-multiselect
                :filter="true"
                v-model="setorSelecionado"
                v-width="150"
                :options="optionsSetores"
                optionLabel="text"
                class="mr-3"
              >
              </pv-multiselect>

              <pv-multiselect
                :filter="true"
                v-model="tipoNegocioSelecionado"
                v-width="150"
                :options="tiposNegocios"
                optionLabel="text"
                class="mr-3"
              >
              </pv-multiselect>

              <b-button
                class="mr-3"
                variant="primary"
                @click="pegarTodosGraficos"
              >
                Filtrar
              </b-button>
            </div>
          </HeaderFilters>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-alert show variant="warning" v-if="!clienteTemModulo"
            ><b>PÁGINA DE DEMONSTRAÇÃO:</b> OS DADOS APRESENTADOS ABAIXO SÃO
            MERAMENTE ILUSTRATIVOS/FICTÍCIOS.
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-row align-v="stretch">
            <b-col md="12" class="" style="height: 250px">
              <ChartWrapper
                type="area"
                :options="chartOptionsAtendimentos"
                :series="seriesAtendimentos"
                subtitle="Atendimentos"
                height="165"
              >
                <div class="ml-4 mt-4 d-flex">
                  <b-icon-people-fill />
                  <h3 class="ml-1 titulo-card">
                    {{ dados.qtdPacientesAtendidos }}
                  </h3>
                  <PercentageBadge :percentage="dados.porcentagemAtendidos" />
                </div>
              </ChartWrapper>
            </b-col>

            <b-col md="12" style="height: 250px" class="mt-6">
              <b-row class="h-100">
                <b-col md="12" class="h-100">
                  <ClientProfileChart
                    :perfilSeries="perfilClientes"
                    :perfilCategories="perfilCategories"
                    :respondidoObj="respondidoObj"
                    :naoRespondidoObj="naoRespondidoObj"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <ChartCard>
            <div id="map" ref="map" class="h-100 w-100"></div>
          </ChartCard>
        </b-col>
      </b-row>
      <b-row align-v="stretch" class="mt-6">
        <b-col md="6" style="height: 320px">
          <ChartWrapper
            type="bar"
            height="200"
            :options="chartOptionsConvenios"
            :series="seriesConvenios"
            title="Convênios"
            subtitle="Utilizados na unidade"
          />
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col style="height: 320px">
              <WhiteDataTabs
                :firstItems="itensUnidades"
                :firstFields="fieldsUnidades"
                :secondItems="itensSetores"
                :secondFields="fieldsSetores"
                firstSectionTitle="Unidades"
                secondSectionTitle="Setores"
                :semCepsRegistrados="semUnidadesESetores"
              />
            </b-col>
            <b-col style="height: 320px">
              <WhiteDataTabs
                :firstItems="itensCidades"
                :firstFields="fieldsCidades"
                :secondItems="itensBairros"
                :secondFields="fieldsBairros"
                firstSectionTitle="Cidades"
                secondSectionTitle="Bairros"
                :semCepsRegistrados="semCidadesEBairros"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row style="height: 250px" class="mt-6">
        <b-col md="6">
          <b-row>
            <b-col md="6" style="height: 250px">
              <ChartWrapper
                type="donut"
                height="200"
                :options="chartOptionsCid"
                :series="seriesCid"
                title="CID-10"
                subtitle="Índice de doenças"
              />
            </b-col>
            <b-col style="height: 250px" md="6">
              <ChartWrapper
                type="bar"
                height="200"
                :options="optionsCadastroRetorno"
                :series="seriesCadastroRetorno"
                title="Cadastro x Atendimentos"
              />
            </b-col>
          </b-row>
        </b-col>

        <b-col md="6" style="height: 250px">
          <ChartWrapper
            type="bar"
            :options="chartOptionsEngajamento"
            :series="seriesEngajamento"
            title="Engajamento"
            height="175"
            subtitle="Relacionamento com os clientes"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import HeaderFilters from "./components/HeaderFilters.vue";
import ChartWrapper from "./components/ChartWrapper.vue";
import ClientProfileChart from "./components/ClientProfileChart.vue";
import WhiteDataTabs from "./components/WhiteDataTabs.vue";
import OverlayModuleSignUp from "./components/OverlayModuleSignUp.vue";
import PercentageBadge from "./components/PercentageBadge.vue";
import ChartCard from "./components/ChartCard.vue";

import predio from "./predios-kalabanga/icons8-prédio-40.png";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { ADD_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

import ApiService from "@/core/services/api.service";
import axios from "axios";

import { mapGetters } from "vuex";
import Toast from "primevue/toast";
import moment from "moment";
import config from "@/core/config/layout.config.json";

export default {
  components: {
    Toast,
    HeaderFilters,
    ChartWrapper,
    ClientProfileChart,
    WhiteDataTabs,
    OverlayModuleSignUp,
    PercentageBadge,
    ChartCard
  },
  provide() {
    return {
      perfilSeries: this.perfilSeries
    };
  },
  directives: {
    width: {
      bind(el, binding) {
        el.style.width = binding.value + "px";
      }
    }
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    arrayDeUnidadesSelecionadas() {
      return this.unidadeSelecionada.map((v) => v.value);
    },
    arrayDeSetoresSelcionados() {
      return this.setorSelecionado.map((v) => v.value);
    }
  },

  data() {
    return {
      interval: null,
      semUnidadesESetores: false,
      semCidadesEBairros: false,
      atendimentosQtdAglutinacao: 4,
      cidQtdAglutinacao: 10,
      conveniosQtdAglutinacao: 5,
      engajamentoQtdAglutinacao: 8,
      cadastroQtdAglutinacao: 3,
      tipoDeNegocio: null,
      mapaTipo: "agrupado",
      naoRespondidoObj: null,
      respondidoObj: null,
      unidades: [],
      cidades: {},
      clientes: {},
      optionsSetores: [],
      setorSelecionado: [],
      optionsUnidades: [],
      unidadeSelecionada: [],
      tiposNegocios: [],
      tipoNegocioSelecionado: [],
      respondidoNaoRespondidoSelecionado: "todos",
      optionsRespondidoNaoRespondido: [
        {
          text: "Todos",
          value: "todos"
        },
        {
          text: "Respondido",
          value: "respondido"
        },
        {
          text: "Não respondido",
          value: "nao-respondido"
        }
      ],
      periodoValor: "US",
      dataInicial: null,
      dataFinal: null,
      markersPacientes: [],
      markClusteresUnidades: [],
      mapaCarregado: null,
      opcoesUnidades: [],
      boudUnidades: null,
      mapaRealOriginal: null,
      markCLusteres: [],
      heatmap: null,
      bounds: null,
      clienteTemModulo: true,
      dadosMapa: {},
      pacientes: [],
      dados: {
        porcentagemAtendidos: 0,
        qtdPacientesAtendidos: 0
      },
      filtro: {
        data_inicial: "2022-03-10",
        data_final: "2022-06-22"
      },
      labelDropdownFiltro: "Hoje: " + moment().format("DD/MM/YYYY"),
      classCarousel: 0,
      form: {
        nome: "",
        telefone: ""
      },
      mostrarOverlay: false,
      seriesCadastroRetorno: [
        {
          name: "1 retorno",
          data: [44, 55, 41, 12, 44]
        },
        {
          name: "2 retornos",
          data: [13, 23]
        },
        {
          name: ">2 retornos",
          data: [11, 17, 33]
        }
      ],
      optionsCadastroRetorno: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10
          }
        },
        xaxis: {
          categories: ["< 1 ano", "2 anos", "5 anos", "22 anos"],
          type: "category",
          tickPlacement: "between"
        },
        legend: {
          position: "bottom",
          offsetY: 0
        },
        fill: {
          opacity: 1
        }
      },

      seriesAtendimentos: [],
      chartOptionsAtendimentos: {
        chart: {
          height: 180,
          type: "area",
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        xaxis: {
          type: "category",
          categories: ["2022-07-09", "2022-07-09", "2022-07-10"],
          labels: {
            formatter: function (value, timestamp) {
              return moment(value).format("DD/MM/YYYY");
            }
          }
        },
        tooltip: {
          x: {
            format: "yy/MM/dd"
          }
        }
      },
      seriesNovosRecorrentes: [10, 55],
      chartOptionsNovosRecorrentes: {
        chart: {
          type: "donut",
          toolbar: {
            show: false
          }
        },
        labels: ["Novos", "Recorrentes"],
        dataLabels: {
          enabled: false
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      },
      seriesConvenios: [],
      chartOptionsConvenios: {
        chart: {
          type: "bar",
          height: 350
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: ["Particular", "SUS", "Plano 2", "Outros"]
        }
      },
      seriesCid: [10, 55, 34, 65],
      chartOptionsCid: {
        chart: {
          type: "donut"
        },
        legend: {
          fontSize: "10px",
          position: "bottom"
        },
        plotOptions: {
          pie: {}
        },
        dataLabels: {
          enabled: false
        },
        labels: ["D56", "D70", "D86", "Q81"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              }
            }
          }
        ]
      },
      seriesEngajamento: [
        {
          name: "Respondido",
          data: [360, 230, 200, 180, 210, 270, 360, 230, 200, 180, 210, 270]
        },
        {
          name: "Não respondido",
          data: [
            -2000, -1800, -2200, -2250, -1700, -1900, -2000, -1800, -2200,
            -2250, -1700, -1900
          ]
        }
      ],
      chartOptionsEngajamento: {
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            borderRadius: [8, 8],
            columnWidth: "40%"
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ["#26a0fc", "#d9920a"],
        xaxis: {
          type: "datetime",
          categories: [
            "01/01/2022 GMT",
            "01/02/2022 GMT",
            "01/03/2022 GMT",
            "01/04/2022 GMT",
            "01/05/2022 GMT",
            "01/06/2022 GMT",
            "01/07/2022 GMT",
            "01/08/2022 GMT",
            "01/09/2022 GMT",
            "01/10/2022 GMT",
            "01/11/2022 GMT",
            "01/12/2022 GMT"
          ]
        },
        legend: {
          position: "right",
          offsetY: 40
        },
        fill: {
          opacity: 1
        }
      },
      fieldsUnidades: [
        {
          key: "nome",
          label: ""
        },
        {
          key: "atendimento",
          label: "",
          tdClass: "d-flex justify-content-end align-items-center"
        }
      ],
      itensUnidades: [
        { nome: "Empresa 1", atendimento: "422", crescimento: 3.5 },
        { nome: "Empresa 2", atendimento: "422", crescimento: 3.5 },
        { nome: "Empresa 3", atendimento: "422", crescimento: 3.5 },
        { nome: "Empresa 4", atendimento: "422", crescimento: 3.5 },
        { nome: "Empresa 5", atendimento: "422", crescimento: 3.5 },
        { nome: "Empresa 6", atendimento: "422", crescimento: 3.5 }
      ],
      fieldsSetores: [
        {
          key: "nome",
          label: ""
        },
        {
          key: "atendimento",
          label: "",
          tdClass: "d-flex justify-content-end align-items-center"
        }
      ],
      itensSetores: [
        { nome: "Setor 1", atendimento: "426" },
        { nome: "Setor 2", atendimento: "422" },
        { nome: "Setor 3", atendimento: "422" },
        { nome: "Setor 4", atendimento: "422" },
        { nome: "Setor 5", atendimento: "422" },
        { nome: "Setor 6", atendimento: "422" }
      ],
      fieldsCidades: [
        {
          key: "nome",
          label: ""
        },
        {
          key: "atendimento",
          label: "",
          tdClass: "d-flex justify-content-end align-items-center"
        }
      ],
      itensCidades: [
        { nome: "Cidade 1", atendimento: "426", crescimento: 3.5 },
        { nome: "Cidade 2", atendimento: "422", crescimento: 3.5 },
        { nome: "Cidade 3", atendimento: "422", crescimento: 3.5 },
        { nome: "Cidade 4", atendimento: "422", crescimento: 3.5 },
        { nome: "Cidade 5", atendimento: "422", crescimento: 3.5 },
        { nome: "Cidade 6", atendimento: "422", crescimento: 3.5 }
      ],
      fieldsBairros: [
        {
          key: "nome",
          label: ""
        },
        {
          key: "atendimento",
          label: "",
          tdClass: "d-flex justify-content-end align-items-center"
        }
      ],
      itensBairros: [
        { nome: "Bairros 1", atendimento: "426", crescimento: 3.5 },
        { nome: "Bairros 2", atendimento: "422", crescimento: 3.5 },
        { nome: "Bairros 3", atendimento: "422", crescimento: 3.5 },
        { nome: "Bairros 4", atendimento: "422", crescimento: 3.5 },
        { nome: "Bairros 5", atendimento: "422", crescimento: 3.5 },
        { nome: "Bairros 6", atendimento: "422", crescimento: 3.5 }
      ],
      seriesRetorno: [100, 120, 80],
      chartOptionsRetorno: {
        chart: {
          type: "donut"
        },
        legend: {
          fontSize: "10px",
          position: "bottom"
        },
        plotOptions: {
          pie: {}
        },
        dataLabels: {
          enabled: false
        },
        labels: ["Uma vez", "Duas vezes", "Outros"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      },
      seriesAntigos: [400, 150, 100, 80],
      chartOptionsAntigos: {
        chart: {
          type: "donut"
        },
        legend: {
          fontSize: "10px",
          position: "bottom"
        },
        plotOptions: {
          pie: {}
        },
        dataLabels: {
          enabled: false
        },
        labels: [
          "Um ano",
          "Dois anos",
          "Três anos",
          "Cinco anos",
          "Seis anos",
          "Outros"
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      },
      series: [14, 23, 21, 17, 15, 10, 12, 17, 21],
      chartOptions: {
        chart: {
          type: "polarArea"
        },
        labels: [
          "0-10",
          "11-20",
          "21-30",
          "31-40",
          "41-50",
          "51-60",
          "61-70",
          "71-80",
          "81-90"
        ],
        stroke: {
          colors: ["#fff"]
        },
        fill: {
          opacity: 0.8
        },
        legend: {
          fontSize: "10px",
          position: "bottom"
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      },
      perfilClientes: {
        faixasEtarias: {
          "Não Respondidos": {
            idade_0_10: 395,
            idade_11_20: 313,
            idade_21_30: 390,
            idade_31_40: 731,
            idade_41_50: 778,
            idade_51_60: 710,
            idade_61_70: 694,
            idade_71_80: 445,
            idade_81_90: 187,
            idade_91_99: 27
          },
          Respondidos: {
            idade_0_10: 433,
            idade_11_20: 482,
            idade_21_30: 1059,
            idade_31_40: 1657,
            idade_41_50: 1996,
            idade_51_60: 1662,
            idade_61_70: 1290,
            idade_71_80: 789,
            idade_81_90: 366,
            idade_91_99: 44
          }
        }
      },
      perfilCategories: [
        "0-10",
        "11-20",
        "21-30",
        "31-40",
        "41-50",
        "51-60",
        "61-70",
        "71-80",
        "81-90",
        "91-99"
      ],
      perfilSeries: [
        {
          data: [20, 25, 23, 12, 1, 28, 26, 24, 20, 10],
          name: "Não respondidos"
        },
        {
          data: [15, 20, 28, 19, 5, 20, 21, 20, 25, 13],
          name: "Respondidos"
        }
      ]
    };
  },
  methods: {
    async pegarTiposNegocio() {
      let base_url = "https://bi.wellon.digital";
      // let url = this.dataBI.url.replace("https://", "");
      // url = url.substring(url.indexOf("/"));

      const response = await axios.get(
        "https://bi.wellon.digital/laboclin/api/mapeamento/tipos-negocios",
        {
          headers: {
            Authorization: `Bearer ${this.dataBI.token}`
          }
        }
      );

      const tiposDeNegocios = response.data.map((v) => {
        return {
          text: v.nome,
          value: v.id
        };
      });

      this.tiposNegocios = tiposDeNegocios;
      this.tipoNegocioSelecionado = tiposDeNegocios;
      // let response = await ApiService.get("/mapeamento/tipos-negocios", {
      //   baseURL: base_url,
      //   headers: {
      //     Authorization: `Bearer ${this.dataBI.token}`
      //   }
      // });
    },
    checarSeEstaNoFundoDaPagina() {
      const totalPageHeight = document.body.scrollHeight;
      const scrollPoint = window.scrollY + window.innerHeight;
      if (scrollPoint >= totalPageHeight - 200) {
        this.mostrarOverlay = true;
        this.$modal.show("modal-1");
      } else {
        this.mostrarOverlay = false;
      }
    },
    adicionarDadosFakeNosGraficos() {
      this.naoRespondidoObj = {
        sexo: "F",
        faixa_etaria: "31-40",
        convenio: "PARTICULAR",
        horario: "07 horas",
        dia: "Segunda-feira"
      };
      this.respondidoObj = {
        sexo: "F",
        faixa_etaria: "21-30",
        convenio: "PARTICULAR",
        horario: "09 horas",
        dia: "Terça-feira"
      };
      this.seriesAtendimentos = [
        {
          name: "Atendimentos",
          data: [
            { y: 1, x: 1 },
            { y: 2, x: 2 },
            { y: 2, x: 3 }
          ]
        }
      ];
      this.dados = {
        porcentagemAtendidos: 7.4,
        qtdPacientesAtendidos: 405
      };
      this.seriesConvenios = [
        {
          data: [400, 253, 140, 120]
        }
      ];
      this.seriesCid = [10, 55, 34, 65];
      this.seriesEngajamento = [
        {
          name: "Respondido",
          data: [360, 230, 200, 180, 210, 270, 360, 230, 200, 180, 210, 270]
        },
        {
          name: "Não respondido",
          data: [
            -2000, -1800, -2200, -2250, -1700, -1900, -2000, -1800, -2200,
            -2250, -1700, -1900
          ]
        }
      ];
      this.$forceUpdate();
    },
    onChangeMultiselectUnidades(e) {
      this.pegarTodosGraficos();
    },
    onChangeMultiSelectSetores(e) {
      this.pegarTodosGraficos();
    },
    onChangeMapaTipo(value) {
      this.mapaTipo = value;
    },
    async pegarDadosTeixeiraOtimizadorDeCodigo() {
      const dadosDeTodosOsGraficosExcetoMapa = await this.requestURL(
        "mapeamento/dados-atendimentos",
        this.periodoValor,
        this.dataInicial,
        this.dataFinal,
        3
      );

      this.pegarDadosClientesAtendidos(
        dadosDeTodosOsGraficosExcetoMapa.qtdPacientesPorHora,
        dadosDeTodosOsGraficosExcetoMapa.qtdPacientesAtendidos,
        dadosDeTodosOsGraficosExcetoMapa.porcentagemAtendimentos
      );

      this.pegarDadosTempoRetorno(
        dadosDeTodosOsGraficosExcetoMapa.tempoCadastro
      );

      this.pegarDadosConvenios(dadosDeTodosOsGraficosExcetoMapa.convenios);

      this.pegarDadosPerfilClientes(
        dadosDeTodosOsGraficosExcetoMapa.perfilClientes
      );

      this.pegarDadosEngajamento(
        Object.values(dadosDeTodosOsGraficosExcetoMapa.engajamento)
      );

      this.pegarDadosCid(dadosDeTodosOsGraficosExcetoMapa.cid);
    },
    async pegarTodosGraficos() {
      // this.mapaSelecionado = "agrupado";
      this.mapaTipo = "agrupado";
      this.$forceUpdate();
      await this.pegarDadosMapa();
      await this.initMap();

      await this.pegarDadosTeixeiraOtimizadorDeCodigo();
      // await this.pegarDadosClientesAtendidos();
      // await this.pegarDadosTempoRetorno();
      // await this.pegarDadosPerfilClientes();
      // await this.pegarDadosEngajamento();

      // os de cima ja foi

      // await this.pegarDadosCid();

      // await this.pegarDadosConvenios();
    },
    async pegarDadosMapa() {
      const dadosMapa = await this.requestURL(
        "mapeamento/dados-mapa",
        this.periodoValor,
        this.dataInicial,
        this.dataFinal,
        null,
        this.respondidoNaoRespondidoSelecionado
      );

      this.dadosMapa = dadosMapa;
      this.pacientes = this.dadosMapa.latLngPacientes;

      const bairrosArr = [];

      this.itensCidades = dadosMapa.cidades
        .map((v) => {
          v.bairros.map((value) => {
            const obj = {};
            obj.nome = value.nome;
            obj.atendimento = value.qtd + "";
            bairrosArr.push(obj);
          });
          return {
            nome: v.nome,
            atendimento: v.totalPacientes,
            crescimento: v.porcentagem.toFixed(0)
          };
        })
        .sort((a, b) => parseFloat(b.atendimento) - parseFloat(a.atendimento));
      this.itensBairros = bairrosArr.sort(
        (a, b) => parseFloat(b.atendimento) - parseFloat(a.atendimento)
      );
      if (this.itensBairros.length == 0 || this.itensCidades.length == 0) {
        this.semCidadesEBairros = true;
      } else {
        this.semCidadesEBairros = false;
      }

      if (this.optionsUnidades.length === 0) {
        this.optionsUnidades = dadosMapa.unidades.map((v) => {
          return {
            text: v.nome,
            value: v.codigo
          };
        });

        this.unidadeSelecionada = [...this.optionsUnidades];
      }

      this.unidades = dadosMapa.unidades.map((v) => {
        return {
          lat: Number(v.lat),
          lng: Number(v.lng),
          nome: v.nome
        };
      });

      this.pacientes = dadosMapa.latLngPacientes.map((v) => {
        return {
          lat: Number(v.lat),
          lng: Number(v.lng)
        };
      });

      const dadosParaInserirNosSetores = [];

      this.itensUnidades = dadosMapa.unidades
        .map((v) => {
          v.setores.map((value) => {
            const obj = {};
            obj.nome = value.nome;
            obj.atendimento = value.qtd + "";
            obj.codigo = value.codigo;
            dadosParaInserirNosSetores.push(obj);
          });

          return {
            nome: v.nome,
            atendimento: v.totalPacientes,
            crescimento: v.porcentagem.toFixed(0)
          };
        })
        .sort((a, b) => parseFloat(b.atendimento) - parseFloat(a.atendimento));

      this.itensSetores = dadosParaInserirNosSetores.sort(
        (a, b) => parseFloat(b.atendimento) - parseFloat(a.atendimento)
      );
      if (this.optionsSetores.length === 0) {
        this.optionsSetores = this.itensSetores.map((v) => {
          return {
            text: v.nome,
            value: v.codigo
          };
        });
        if (this.itensUnidades.length == 0 || this.itensSetores.length == 0) {
          this.semUnidadesESetores = true;
        } else {
          this.semUnidadesESetores = false;
        }
        this.setorSelecionado = [...this.optionsSetores];
        if (this.semUnidadesESetores || this.semCidadesEBairros) {
          this.$toast.add({
            severity: "info",
            summary: "Sem dados de CEP",
            detail: `Alguns gráficos podem não apresentar comportamento esperado pois não há dados de CEP cadastrados para as unidades e setores selecionados.`,
            life: 10000
          });
        }
      }
    },
    pegarDadosCid(dadosCid) {
      dadosCid = dadosCid.filter((v) => v.doenca.length > 0);

      this.chartOptionsCid = {
        chart: {
          type: "donut"
        },
        legend: {
          fontSize: "10px",
          position: "bottom"
        },
        plotOptions: {
          pie: {}
        },
        dataLabels: {
          enabled: false
        },
        labels: dadosCid.map((v) => v.doenca),
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      };

      this.$forceUpdate();
      this.seriesCid = dadosCid.map((v) => v.total);
      this.$forceUpdate();
    },
    pegarDadosTempoRetorno(tempoCadastro) {
      this.seriesCadastroRetorno = tempoCadastro.map((v, i) => {
        return {
          name: i + " retornos",
          data: v.retornos
            .sort((a, b) => a.vezes - b.vezes)
            .map((val) => val.qtd)
        };
      });

      this.optionsCadastroRetorno = {
        chart: {
          type: "bar",
          height: 200,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10
          }
        },
        xaxis: {
          categories: tempoCadastro.map((v) =>
            v.anos === 0
              ? "<1  ano"
              : v.anos === "Outros"
              ? v.anos
              : v.anos + " anos"
          ),
          type: "category",
          tickPlacement: "between"
        },
        legend: {
          position: "bottom",
          offsetY: 0
        },
        fill: {
          opacity: 1
        }
      };
    },
    pegarDadosClientesAtendidos(
      qtdPacientesPorHora,
      qtdPacientesAtendidos,
      porcentagemAtendimentos
    ) {
      // aglutinacao 4
      this.$forceUpdate();

      this.seriesAtendimentos = [
        {
          name: "Atendimentos",
          data: qtdPacientesPorHora.map((v, i) => {
            return {
              x: new Date(v.data_hora.substring(0, 11)),
              y: v.qtd
            };
          })
        }
      ];
      this.$forceUpdate();

      this.chartOptionsAtendimentos.categories = [
        this.dataInicial,
        this.dataFinal
      ];

      this.dados.porcentagemAtendidos = porcentagemAtendimentos;
      this.dados.qtdPacientesAtendidos =
        qtdPacientesAtendidos.toLocaleString("pt-BR");
      // this.$forceUpdate();  this.$forceUpdate();
      this.$forceUpdate();
    },

    formatarDataParaGraficoEngajemtno(data) {
      const dia = data.split("-")[2];
      const mes = data.split("-")[1];
      const ano = data.split("-")[0];
      return mes + "/" + dia + "/" + ano + " GMT";
    },
    pegarDadosEngajamento(dadosEngajamento) {
      this.chartOptionsEngajamento = {
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            borderRadius: [8, 8],
            columnWidth: "40%"
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ["#26a0fc", "#d9920a"],
        xaxis: {
          type: "datetime",
          categories: dadosEngajamento.map((v) =>
            this.formatarDataParaGraficoEngajemtno(v.dia)
          )
        },
        legend: {
          position: "right",
          offsetY: 40
        },
        fill: {
          opacity: 1
        }
      };
      this.$forceUpdate();
      this.seriesEngajamento = [
        {
          name: "Respondido",
          data: dadosEngajamento.map((v) => parseInt(v.r))
        },
        {
          name: "Não respondido",
          data: dadosEngajamento.map((v) => -parseInt(v.nr))
        }
      ];
      this.$forceUpdate();
    },

    pegarDadosPerfilClientes(dadosDePerfil) {
      this.naoRespondidoObj = dadosDePerfil.nao_respondidos;
      this.respondidoObj = dadosDePerfil.respondidos;

      // Verificando se os 'Não Respondidos' é null
      if (!dadosDePerfil.qtd_nao_respondidos) {
        this.perfilSeries[0].data = this.perfilSeries[0].data.map((_) => 0);
      } else {
        this.perfilSeries[0].data = Object.values(
          dadosDePerfil.qtd_nao_respondidos
        );
      }

      // Verificando se os 'Respondidos' é null
      if (!dadosDePerfil.qtd_respondidos) {
        this.perfilSeries[1].data = this.perfilSeries[1].data.map((_) => 0);
      } else {
        this.perfilSeries[1].data = Object.values(
          dadosDePerfil.qtd_respondidos
        );
      }

      this.$forceUpdate();
    },
    tratarNomesDeConvenios(nomeConvenio) {
      if (nomeConvenio.length > 7) {
        return nomeConvenio.substring(0, 6) + "...";
      }
      // Adicionar hover de aparecer o nome ou algo do tipo.
      return nomeConvenio;
    },
    pegarDadosConvenios(convenios) {
      this.chartOptionsConvenios.xaxis.categories = convenios.map(
        (v) => Object.keys(v)[0]
      );
      // this.$forceUpdate();

      this.seriesConvenios = [{ data: convenios.map((v) => v.qtd) }];
      this.chartOptionsConvenios = {
        chart: {
          type: "bar",
          height: 240
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: convenios.map((v) => v.convenio)
        }
      };
      this.$forceUpdate();
    },
    carregarMapaAgrupado() {
      this.limparMapa();

      let markers = this.criarMarkers();
      this.markClusteresUnidades = new MarkerClusterer({
        markers,
        map: this.mapaCarregado
      });
      markers = this.criarMarkersPacientes();
      this.markersPacientes = markers;
      this.markerClustererPacientes = new MarkerClusterer({
        markers,
        map: this.mapaCarregado
      });
    },
    carregarHeatmap() {
      this.limparMapa();
      const positions = this.pacientes.map((v) => {
        return new window.google.maps.LatLng(Number(v.lat), Number(v.lng));
      });
      this.heatmap.setData(positions);

      this.heatmap.setOptions({
        dissipating: true,
        maxIntensity: 20,
        radius: 15
      });
      this.heatmap.setMap(this.mapaRealOriginal);
    },
    carregarMapa(center, heatmap = false) {
      this.heatmap = new window.google.maps.visualization.HeatmapLayer();
      this.mapaRealOriginal = new window.google.maps.Map(this.$refs["map"], {
        zoom: 10,
        center: center,

        styles: [
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }]
          }
        ]
      });
      return this.mapaRealOriginal;
    },
    limparMapa() {
      // this.markCLusteres.clearMarkers();
      // this.markersPacientes.clearMarkers();
      this.markersPacientes.map((v) => {
        v.setMap(null);
      });
      this.markerClustererPacientes.clearMarkers();
      this.markClusteresUnidades.clearMarkers();
      this.heatmap.setMap(null);
    },
    criarMarkersPacientes() {
      const markers = this.pacientes.map((position, i) => {
        this.bounds.extend(position);
        const marker = new window.google.maps.Marker({
          position
        });

        return marker;
      });
      return markers;
    },
    criarMarkersIndividuais() {
      // this.initMap(true);
      this.limparMapa();
      this.markersPacientes.map((v) => {
        v.setMap(this.mapaRealOriginal);
      });
    },
    criarMarkers() {
      const markers = this.unidades.map((position, i) => {
        this.boudUnidades.extend(position);

        const marker = new window.google.maps.Marker({
          position,
          icon: predio
          // label
        });
        return marker;
      });
      return markers;
    },
    async initMap(zerar = null) {
      let center = { lat: -15.769089236892697, lng: -48.04139939304531 };
      if (!this.clienteTemModulo) {
        this.mapaCarregado = this.carregarMapa(center);
        let map = this.mapaCarregado;
        let [pacientes, unidades] = this.gerarPontoAleatorios(center);
        // console.log(markers);

        this.markerClustererPacientes = new MarkerClusterer({
          markers: pacientes,
          map
        });
        this.markClusteresUnidades = new MarkerClusterer({
          markers: unidades,
          map
        });

        return;
      }

      await navigator.geolocation.getCurrentPosition(
        (position) => {
          let lat = position.coords.latitude;
          let lng = position.coords.longitude;
          center = { lat, lng };

          // this.clientes = this.dados.pacientes;
          this.mapaCarregado = this.carregarMapa(center);
          let map = this.mapaCarregado;
          let markers = this.criarMarkers();

          this.markClusteresUnidades = new MarkerClusterer({ markers, map });
          markers = this.criarMarkersPacientes();
          this.markersPacientes = markers;
          this.markerClustererPacientes = new MarkerClusterer({ markers, map });
          map.fitBounds(this.bounds);
        },
        (error) => {
          // console.log(error.message);
        }
      );
      this.mapaCarregado = this.carregarMapa(center);

      if (Object.keys(this.unidades).length > 0) {
        this.unidades = this.dadosMapa.unidades.map((v) => {
          return {
            lat: Number(v.lat),
            lng: Number(v.lng),
            nome: v.nome
          };
        });
      } else {
        this.unidades = [center]; //TODO: REMOVER
        this.unidades = [];
        this.unidades.push(center);
        this.unidades = this.gerarPontoAleatorios(center);
      }
    },
    gerarPontoAleatorios(center, raio = 0.1) {
      // Gerando pontos aleatórios próximos das unidades
      const numeroDeUnidades = 2;

      let pontos = [];
      for (let j = 0; j < 200; j++) {
        let pn = Math.floor(Math.random() * 1000) % 2 == 0 ? -1 : 1;
        let lat = center.lat + Math.random() * raio * pn;
        pn = Math.floor(Math.random() * 1000) % 2 == 0 ? -1 : 1;
        let lng = center.lng + Math.random() * raio * pn;
        let obj = { lat, lng };
        pontos.push(obj);
      }

      const pacientes = pontos.slice(numeroDeUnidades).map((position, i) => {
        this.bounds.extend(position);
        const marker = new window.google.maps.Marker({
          position
        });

        return marker;
      });

      const unidades = pontos.slice(0, numeroDeUnidades).map((position, i) => {
        this.bounds.extend(position);
        const marker = new window.google.maps.Marker({
          position,
          icon: predio
        });

        return marker;
      });

      return [pacientes, unidades];
    },

    esconderOverlay() {
      this.mostrarOverlay = false;
      localStorage.bloquearOverlay = true;
      // this.$route.query.overlay = false;
    },

    getWithExpiry(key) {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    },

    async dataBI() {
      let response = await ApiService.get("/nps");

      let dataBI = response.data.data;
      return dataBI;
    },

    carregarUnidadesMapa() {
      Object.keys(this.dados.unidades).foreach((i) => {
        this.itensUnidades.push({
          nome: i
        });
      });
    },

    async requestURL(
      rota,
      periodo = "personalizado",
      dataInicial = "2022-01-01",
      dataFinal = "2022-07-01"
    ) {
      let url = this.dataBI.url.replace("https://", "");
      url = url.substring(url.indexOf("/"));
      let base_url = "https://bi.wellon.digital"; // TODO: REMOVER, APENAS PARA TESTE.

      const payload = {
        data_inicial: dataInicial,
        data_final: dataFinal,
        periodo: periodo,

        atendimentos_qtd_aglutinacao: this.atendimentosQtdAglutinacao,
        cid_qtd_aglutinacao: this.cidQtdAglutinacao,
        convenios_qtd_aglutinacao: this.conveniosQtdAglutinacao,
        engajamento_qtd_aglutinacao: this.engajamentoQtdAglutinacao,
        cadastro_qtd_aglutinacao: this.cadastroQtdAglutinacao,
        tipo_negocio: this.tipoNegocioSelecionado.map((v) => v.value + ""),

        nps_respondido: this.respondidoNaoRespondidoSelecionado,
        unidades: this.arrayDeUnidadesSelecionadas,
        setores: this.arrayDeSetoresSelcionados
      };

      let response = await ApiService.post(url + rota, payload, {
        baseURL: base_url,
        headers: {
          Authorization: `Bearer ${this.dataBI.token}`
        },
        body: {
          nps_respondido: "nao-respondido",
          nps_aglutinacao: "nao-aglutinado"
        }
      });

      return response.data;
    }
  },
  async mounted() {
    let newConfig = JSON.parse(JSON.stringify(config));
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Mapeamento de Perfil" }]);
    this.$store.dispatch(ADD_BODY_CLASSNAME, "aside-minimize");
    newConfig.styleContetnt = { padding: "0" };
    newConfig.styleContainer = { "max-width": "100%", margin: 0 };
    newConfig.aside.self.minimize.hoverable = true;
    this.$store.dispatch(SET_LAYOUT_CONFIG, newConfig);

    this.bounds = new window.google.maps.LatLngBounds();
    this.boudUnidades = new window.google.maps.LatLngBounds();

    this.filtro.data_final = moment().format("YYYY-MM-DD hh:mm:ss");
    this.filtro.data_inicial = moment()
      .subtract(6, "month")
      .locale("pt-br")
      .format("YYYY-MM-DD hh:mm:ss");

    // Verificar se o cliente tem permissão para acessar o mapeamento de perfil
    let response = await ApiService.get("/conta/recurso-ativo");
    response = response.data.data;
    this.clienteTemModulo = response.some(
      (recurso) => recurso.rec_codigo === "MAPEAMENTO_PERFIL"
    );
    this.initMap();
    if (this.clienteTemModulo) {
      this.dataBI = await this.dataBI();
      await this.pegarTiposNegocio();
      this.mostrarOverlay = false;

      this.$bus.$on(
        "atualizar:mapeamento-perfil",
        async function (periodo) {
          this.dataInicial = periodo.dataInicial.format("YYYY-MM-DD");
          this.dataFinal = periodo.dataFinal.format("YYYY-MM-DD");
          this.periodoValor = periodo.periodo.value;

          await this.pegarTodosGraficos();

          this.$forceUpdate();
        }.bind(this)
      );

      this.pegarTodosGraficos();
    } else {
      this.adicionarDadosFakeNosGraficos();
      window.addEventListener("scroll", this.checarSeEstaNoFundoDaPagina);
      // window.addEventListener("scroll", this.eventoRolagemDebounced);
    }
    this.form.nome = this.currentUserPersonalInfo.nome;
    this.form.telefone = this.currentUserPersonalInfo.celular;

    // this.eventoRolagemDebounced();
    // setInterval to show message of cliente sem dados de cep if semUnidadesESetores || semCidadesEBairros
    this.interval = setInterval(() => {
      // show toast
      if (this.semUnidadesESetores || this.semCidadesEBairros) {
        this.$toast.add({
          severity: "info",
          summary: "Sem dados de CEP",
          detail: `Alguns gráficos podem não apresentar comportamento esperado pois não há dados de CEP cadastrados para as unidades e setores selecionados.`,
          life: 5000
        });
      }
    }, 60000);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checarSeEstaNoFundoDaPagina);
    localStorage.removeItem("bloquearOverlay");
    clearInterval(this.interval);
  }
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}

.vue-daterange-picker {
  min-width: 0 !important;
  padding-top: 27px;

  .daterangepicker {
    font-family: Poppins, Helvetica, "sans-serif";

    &:after,
    &:before {
      display: none !important;
    }

    &.opensleft {
      right: 6px;
    }

    .ranges {
      ul {
        width: 160px !important;
      }

      li {
        padding: 0.7rem 1.75rem;
        font-weight: 500;
        font-size: 1rem;
        color: #7e8299;
        transition: color 0.3s ease;

        &:hover,
        &.active {
          background-color: #f3f6f9;
          color: #3699ff;
          transition: color 0.3s ease;
        }
      }
    }
  }

  .reportrange-text {
    display: none !important;
  }
}

.vue-daterange-picker .show-ranges.hide-calendars {
  width: 160px !important;
  min-width: 160px !important;
}

@media screen and (min-width: 768px) {
  .daterangepicker.show-ranges.show-weeknumbers,
  .daterangepicker.show-ranges {
    min-width: 706px !important;
  }
}

::v-deep .paddind-top0 > .card-header {
  padding-top: 8px !important;
}
::v-deep .form-group {
  margin-bottom: 0;
}

.titulo-card {
  font-size: 1.5rem;
}

.subtitulo-card {
  font-size: 0.9rem;
  color: rgb(113, 113, 113);
}

.titulo-card-tabs {
  font-size: 1.3rem;
}

.subtitulo-card-tabs {
  font-size: 0.8rem;
  color: rgb(113, 113, 113);
}

.tabela {
  max-height: 210px;
  overflow-y: auto;
  scrollbar-width: thin;
}

::v-deep .apexcharts-toolbar {
  display: none !important;
}
</style>
