<template>
  <ChartCard>
    <div class="ml-4 mt-4 p-0">
      <h3 class="titulo-card">Perfil de clientes</h3>
      <h4 class="subtitulo-card">Por idade</h4>
    </div>
    <div class="d-flex h-100 w-100">
      <div
        style="
          width: 400px;
          padding-left: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <ChartClientesWindRose />
      </div>
      <div
        class="d-flex justify-content-between h-100"
        style="width: 95%"
        v-if="naoRespondidoObj || respondidoObj"
      >
        <AnswerUniqueTableVue
          :answerObj="respondidoObj"
          title="Respondido"
          v-if="respondidoObj"
        />
        <AnswerUniqueTableVue
          :answerObj="naoRespondidoObj"
          title="Não Respondido"
          v-if="naoRespondidoObj"
        />
      </div>
    </div>
  </ChartCard>
</template>

<script>
import ChartClientesWindRose from "./ChartClientesWindRose.vue";
import AnswerUniqueTableVue from "./AnswerUniqueTable.vue";
import ChartCard from "./ChartCard";

export default {
  props: {
    respondidoObj: {
      type: Object,
      required: false,
      default: null
    },
    naoRespondidoObj: {
      type: Object,
      required: false,
      default: null
    }
  },
  components: {
    ChartClientesWindRose,
    AnswerUniqueTableVue,
    ChartCard
  }
};
</script>

<style lang="scss" scoped>
.titulo-card {
  font-size: 1.5rem;
}
.subtitulo-card {
  font-size: 0.9rem;
  color: rgb(113, 113, 113);
}
</style>
