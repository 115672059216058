<template>
  <ChartCard>
    <div class="ml-4 mt-4 p-0">
      <h3 class="titulo-card"><slot></slot>{{ title }}</h3>
      <h4 class="subtitulo-card">{{ subtitle }}</h4>
    </div>
    <apexchart
      :type="type"
      :height="height"
      :options="chartOptions"
      :series="chartSeries"
      ref="chart"
    ></apexchart>
  </ChartCard>
</template>

<script>
import ChartCard from "./ChartCard.vue";

export default {
  components: {
    ChartCard
  },
  props: {
    type: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: false
    },
    options: {
      type: Object,
      required: true
    },
    series: {
      type: Array,
      required: true
    },
    title: {
      required: false
    },
    subtitle: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      chartOptions: {},
      chartSeries: []
    };
  },
  mounted() {},
  methods: {},
  watch: {
    options: {
      handler(newVal) {
        // clone the array to avoid mutating the original
        // this.chartOptions = JSON.parse(JSON.stringify(newVal));

        this.chartOptions = newVal;
      },
      deep: true,
      immediate: true
    },
    series: {
      handler(newVal) {
        // const newObj = JSON.stringify(newVal);
        // this.chartSeries = JSON.parse(newObj);
        // this.chartSeries = newVal.slice(0);
        this.chartOptions.series = newVal.slice(0);
        if (this.type !== "donut" && this.type !== "bar") {
          if (this.$refs.chart) {
            this.$refs.chart.$el.__vue__.updateSeries(newVal);
          }
        } else {
          this.chartSeries = newVal.slice(0);
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.titulo-card {
  font-size: 1.5rem;
}
.subtitulo-card {
  font-size: 0.9rem;
  color: rgb(113, 113, 113);
}
</style>
