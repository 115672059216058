<template>
  <div
    class="p-4 mb-3 d-flex align-items-end card justify-content-between w-100 flex-row"
  >
    <slot></slot>
    <b-form-group v-slot="{ ariaDescribedby }">
      <b-form-radio-group
        v-model="mapaSelecionado"
        :options="mapas"
        :aria-describedby="ariaDescribedby"
        button-variant="outline-primary"
        size="sm"
        name="radio-btn-outline"
        buttons
        @change="carregarMapaEspecifico"
      ></b-form-radio-group>
    </b-form-group>
  </div>
</template>

<script>
export default {
  emits: [
    "mudarTipo",
    "carregarHeatmap",
    "carregarMapaAgrupado",
    "criarMarkersIndividuais"
  ],
  props: ["tipo", "respondidoNaoRespondidoSelecionado"],
  watch: {
    tipo: {
      handler(newVal) {
        this.mapaSelecionado = newVal;
      },
      deep: true,
      immediate: true
    }
  },

  data() {
    return {
      mapas: [
        { value: "agrupado", text: "Mapa Agrupado" },
        { value: "calor", text: "Mapa de Calor" },
        { value: "individual", text: "Mapa Individual" }
      ],
      mapaSelecionado: "agrupado"
    };
  },

  methods: {
    carregarMapaEspecifico(nomeDoMapa) {
      this.$emit("mudarTipo", nomeDoMapa);
      if (nomeDoMapa === "calor") {
        this.$emit("carregarHeatmap");
      } else if (nomeDoMapa === "agrupado") {
        this.$emit("carregarMapaAgrupado");
      } else {
        this.$emit("criarMarkersIndividuais");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
