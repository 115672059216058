<template>
  <div class="pl-1">
    <b>{{ title }}</b>
    <div class="mt-3">
      <b>Sexo: </b>
      <span>{{ answerObj.sexo }}</span>
    </div>
    <div>
      <b>Faixa etária: </b>
      <span>{{ answerObj.faixa_etaria }}</span>
    </div>
    <div>
      <b>Convenio: </b>
      <span>{{ answerObj.convenio }}</span>
    </div>
    <div>
      <b>Horário: </b>
      <span>{{ answerObj.horario }} horas</span>
    </div>
    <div>
      <b>Dia: </b>
      <span>{{ answerObj.dia }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    answerObj: {
      type: Object,
      required: true,
      default: null
    },
    title: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped></style>
