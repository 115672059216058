<template>
  <highcharts
    class="hc"
    v-height="175"
    :options="chartOptions"
    ref="chart"
  ></highcharts>
</template>

<script>
import { Chart } from "highcharts-vue";

export default {
  inject: ["perfilSeries"],
  directives: {
    height: {
      bind(el, binding) {
        el.style.height = binding.value + "px";
      }
    }
  },
  components: {
    highcharts: Chart
  },
  mounted() {},
  data() {
    return {
      updateArgs: [true, true, { duration: 1000 }],
      chartOptions: {
        chart: {
          polar: true,
          type: "column",
          width: 290,
          height: 185
        },

        title: {
          enabled: false,
          text: "",
          align: "left",
          style: {
            fontFamily: "Roboto,Helvetica,Arial,sans-serif"
          }
        },

        pane: {
          size: 90
        },

        legend: {
          align: "right",
          verticalAlign: "top",
          y: 80,
          x: 10,
          layout: "vertical"
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: [
            "0-10",
            "11-20",
            "21-30",
            "31-40",
            "41-50",
            "51-60",
            "61-70",
            "71-80",
            "81-90",
            "91-99",
            "100+"
          ],
          type: "category",
          uniqueNames: false,
          tickmarkPlacement: "on"
        },
        yAxis: {
          min: 0,
          visible: false,
          endOnTick: false,
          showLastLabel: true,
          labels: {},
          reversedStacks: false
        },
        plotOptions: {
          series: {
            allowPointSelect: true,
            states: {
              hover: {
                color: "#a4edba"
              },
              select: {
                color: "#EFFFEF",
                borderColor: "black",
                dashStyle: "dot"
              }
            },
            stacking: "normal",
            shadow: false,
            groupPadding: 0,
            pointPlacement: "on",
            animation: false
          }
        },
        series: this.perfilSeries,
        colors: [
          "#3c8c5f",
          "#ba9830",
          "#75130b",
          "#6abb8d",
          "#f3c747e6",
          "#c02416e6"
        ]
      }
    };
  },
  created() {},
  methods: {}
};
</script>

<style scoped>
.hc {
  width: 100%;
  height: 100%;
}
</style>
