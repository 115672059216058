<template>
  <b-badge
    class="ml-3 p-1 badge"
    :variant="
      percentage > 0 ? 'success' : percentage === 0 ? 'secondary' : 'danger'
    "
  >
    <b-icon
      v-if="percentage !== 0"
      :icon="percentage > 0 ? 'arrow-up' : 'arrow-down'"
    />
    {{ Math.abs(percentage.toFixed(1)) }}%</b-badge
  >
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss" scoped>
.badge {
  font-size: 0.85rem;
  height: 20px;
}
</style>
