<template>
  <div class="card card-posicao p-0">
    <div class="card-body p-0">
      <div class="d-flex justify-content-end">
        <b-icon-x
          font-scale="2.5"
          class="mr-1 mt-1"
          @click="$emit('hide')"
          style="cursor: pointer"
        ></b-icon-x>
      </div>
      <div
        class="w-100 d-flex justify-content-center align-items-center"
        style="height: 150px"
      >
        <b-icon-gift-fill variant="success" font-scale="10" class="mr-1" />
      </div>
      <div class="p-8">
        <span class="badge badge-pill badge-primary">NOVO</span>

        <div
          id="carouselExampleSlidesOnly"
          class="carousel slide"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            <div
              class="carousel-item"
              :class="classCarousel == 0 ? 'active' : ''"
            >
              <h4 class="mt-6"><b>Entenda melhor os seus clientes</b></h4>
              <h6 class="mt-6 mw-25">
                Tenha um controle melhor de tudo que acontece na empresa. Com
                essa nova funcionalidade você consegue visualizar como os
                clientes se comportam em cada unidade. Além disso, você pode ver
                o perfil dos clientes mais antigos, engajamento dos clientes e
                muito mais. Caso tenha interesse, clique em saiba mais.
              </h6>
              <div class="mt-6 d-flex justify-content-end">
                <b-button variant="info" @click="notificarSaibaMais"
                  >Saiba mais</b-button
                >
              </div>
            </div>
            <div
              class="carousel-item"
              :class="classCarousel == 1 ? 'active' : ''"
            >
              <div class="d-flex justify-content-center align-items-center m-4">
                <b-form @submit="onSubmit">
                  <b-form-group
                    id="input-group-1"
                    label="Nome:"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      v-model="form.nome"
                      type="text"
                      placeholder="Digite seu nome"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="input-group-2"
                    label="Telefone:"
                    label-for="input-2"
                    description="Este telefone será usado para entrar em contato com você."
                  >
                    <b-form-input
                      id="input-2"
                      v-model="form.telefone"
                      type="tel"
                      placeholder="Digite seu telefone"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <div class="d-flex justify-content-end w-100">
                    <b-button type="submit" variant="primary">Enviar</b-button>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      classCarousel: 0,
      form: {
        nome: "",
        telefone: ""
      }
    };
  },

  methods: {
    notificarSaibaMais() {
      this.classCarousel = 1;
      ApiService.post(
        "mapeamento-perfil/notificar-saiba-mais",
        null,
        null,
        false
      ).then((_) => {
        null;
      });
    },
    onSubmit(event) {
      event.preventDefault();
      ApiService.post("mapeamento-perfil/notificar-saiba-mais", this.form).then(
        (response) => {
          this.mostrarOverlay = false;
          this.$modal.hide("modal-1");
          this.setWithExpiry("tagSolicitouMapeamento", true, 43200);
          this.$toast.add({
            severity: "success",
            summary: "Tudo certo!",
            detail:
              "Seus dados foram salvos, logo entraremos em contato com você!",
            life: 7000
          });
        }
      );
    },
    setWithExpiry(key, value, ttl) {
      const now = new Date();
      const item = {
        value: value,
        expiry: now.getTime() + ttl
      };
      localStorage.setItem(key, JSON.stringify(item));
    }
  }
};
</script>

<style lang="scss" scoped>
.card-posicao {
  position: absolute;
  left: -300px;

  .card-body {
    position: fixed;
    width: 380px;
    top: -100px;
    background: #fff;
  }

  top: 50px;
}
</style>
