<template>
  <b-card
    no-body
    class="h-100 p-0"
    style="position: relative; overflow: hidden"
  >
    <div class="overlay-sem-cidade" v-if="semCepsRegistrados">
      <!-- text saying: "Sem CEPs cadastrados." -->
      <h2 class="text-center m-3" style="color: #fff">Sem dados de CEPs.</h2>
    </div>
    <b-tabs card fill nav-class="p-0" class="paddind-top0">
      <b-tab active class="p-0">
        <template #title class="p-0">
          <div class="p-0">
            <h3 class="titulo-card-tabs">{{ firstSectionTitle }}</h3>
          </div>
        </template>
        <div
          class="card-body d-flex flex-column justify-content-between p-0 px-1"
        >
          <div>
            <b-table
              responsive
              hover
              thead-class="d-none"
              class="mostly-customized-scrollbar"
              :items="firstItems"
              :fields="firstFields"
            >
              <template #cell(nome)="data" class="p-0">
                <div class="mt-3">
                  <p style="font-size: 0.9rem">
                    {{ data.value }}
                  </p>
                </div>
              </template>

              <template #cell(atendimento)="data">
                <div class="d-flex flex-content-center mt-3">
                  {{ data.value || "-" }}
                  <!-- variant="success" -->
                  <PercentageBadge :percentage="+data.item.crescimento" />
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </b-tab>
      <b-tab class="p-0">
        <template #title class="p-0">
          <div class="p-0">
            <h3 class="titulo-card">{{ secondSectionTitle }}</h3>
            <!-- <h4 class="subtitulo-card">Atendimentos</h4> -->
          </div>
        </template>
        <div
          class="card-body d-flex flex-column justify-content-between p-0 px-1"
        >
          <div class="">
            <b-table
              responsive
              hover
              thead-class="d-none"
              class="mostly-customized-scrollbar"
              :items="secondItems"
              :fields="secondFields"
            >
              <template #cell(nome)="data" class="p-0">
                <div class="mt-3">
                  <p style="font-size: 0.9rem">
                    {{ data.value }}
                  </p>
                </div>
              </template>

              <template #cell(atendimento)="data">
                <div class="d-flex flex-content-center mt-3">
                  {{ data.value || "-" }}
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import PercentageBadge from "./PercentageBadge.vue";

export default {
  components: {
    PercentageBadge
  },
  props: {
    semCepsRegistrados: {
      type: Boolean,
      required: false
    },
    firstItems: {
      type: Array,
      required: true
    },
    firstFields: {
      type: Array,
      required: true
    },
    secondItems: {
      type: Array,
      required: true
    },
    secondFields: {
      type: Array,
      required: true
    },
    firstSectionTitle: {
      type: String,
      required: false
    },
    secondSectionTitle: {
      type: String,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
.overlay-sem-cidade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #000;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  padding: 0 1rem;
}
.card {
  transition: 0.3s ease;
  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}
.mostly-customized-scrollbar {
  max-height: 210px;
  display: block;
  overflow: auto;
}

.mostly-customized-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: rgb(255, 255, 255); /* or add it to the track */
}

/* Add a thumb */
.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
  background: rgb(227, 227, 227);
}
</style>
